<template>
  <div class="myNFT">
    <watch-head :title="$t('我的NFT收藏')"
                :img="require('@/assets/bracelet_img/47.png')" />

    <div class="content">
      <div class="roleInfo"
           :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('NFT角色信息')}} :</div>
      <div class="info">
        <div class="top">
          <div class="left">
            <div class="name">{{currentVal.name}}</div>
            <div class="cocobern"
                 v-if="list.length > 0">LvL.{{currentVal.grade}}</div>
            <div class="cocobern"
                 v-else
                 style="height:25px;"></div>
            <div class="pic">
              <img :src="currentVal.cover"
                   alt="">
              <div v-show="Object.keys(currentVal).length > 0"
                   v-if="authorNftShow && currentVal.is_hanging_sale == 2"
                   class="higLevel pointer"
                   @click="cancelSell">{{$t('取消挂卖')}}</div>
              <div v-show="Object.keys(currentVal).length > 0"
                   v-if="authorNftShow && currentVal.is_hanging_sale == 0"
                   class="higLevel pointer"
                   @click="sell">{{$t('挂卖')}}</div>
              <div v-show="Object.keys(currentVal).length > 0"
                   v-if="!authorNftShow"
                   class="higLevel pointer"
                   @click="authorNft">{{$t('授权')}}</div>
            </div>
          </div>
          <div class="right">
            <div class="item mar_btm_8">
              <div class="number">{{currentVal.steps || 0}}</div>
              <div class="text">
                <img src="@/assets/bracelet_img/51.png"
                     alt="">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('步数')}}(KM)</span>
              </div>
            </div>
            <div class="item mar_btm_8">
              <div class="number">{{currentVal.heart_rate || 0}}</div>
              <div class="text">
                <img src="@/assets/bracelet_img/50.png"
                     alt="">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('心率')}}(BPM)</span>
              </div>
            </div>
            <div class="item mar_btm_8">
              <div class="number">{{currentVal.calorie || 0}}</div>
              <div class="text">
                <img src="@/assets/bracelet_img/49.png"
                     alt="">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('卡路里')}}(KCAL)</span>
              </div>
            </div>
            <div class="item mar_btm_8">
              <div class="number">{{currentVal.duration || 0}}</div>
              <div class="text">
                <img src="@/assets/bracelet_img/53.png"
                     alt="">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('累计时长(分钟)')}}</span>
              </div>
            </div>
            <div class="item mar_btm_8">
              <div class="number">{{currentVal.durable || 0}} / 100</div>
              <div class="text">
                <img src="@/assets/bracelet_img/52.png"
                     alt="">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('耐用度')}}</span>
              </div>
            </div>
            <div class="item item_width">
              <div class="number">{{currentVal.acount || 0}} / {{currentVal.grade_acount || 0}}</div>
              <div class="text mar_btm_8">
                <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('升级进度')}}</span>
              </div>
              <van-progress stroke-width="6" :percentage="percentage" color="#a7f264" pivot-text="" />
            </div>
          </div>
        </div>
        <div class="roleInfo_btn">
          <van-button v-show="btnType == 'grow'"
                      :loading="regainLoading"
                      :loading-text="$t('升级中...')"
                      class="upgrade btn"
                      :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                      v-if="list.length > 0 && currentVal.durable == 100 && currentVal.is_hanging_sale == 0"
                      @click="upgrade('/successUpgraded')">{{$t('升级')}}</van-button>
          <van-button v-show="btnType == 'info'"
                      :loading="regainLoading"
                      :loading-text="$t('修复中...')"
                      class="repair btn"
                      :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                      v-if="list.length > 0 && currentVal.durable < 100 && currentVal.is_hanging_sale == 0"
                      @click="regainShow = true">{{$t('修复耐久性')}}</van-button>
        </div>
      </div>
    </div>
    <div class="btn_box">
      <div class="btn pointer mar_ri_10"
           :class="{'btn_act': btnType == 'info', 'vn-bold-italic': $i18n.locale == 'vn'}"
           @click="btnType = 'info'">{{$t('信息')}}</div>
      <div class="btn pointer"
           :class="{'btn_act': btnType == 'grow', 'vn-bold-italic': $i18n.locale == 'vn'}"
           @click="btnType = 'grow'">{{$t('成长')}}</div>
    </div>
    <div class="screen">
      <div class="box">
        <div class="circle">
          <img src="@/assets/bracelet_img/54.png"
               alt="">
        </div>
      </div>
    </div>

    <div class="imgList">
      <!-- <div class="imgBox">
                <img src="@/assets/bracelet_img/3.png" alt="">
            </div>
            <img v-for="(item, index) in list" :key="index" :style="`left: calc(18px + ${index * 25}px);z-index: ${list.length - index};`" class="image" src="@/assets/bracelet_img/3.png" alt=""> -->
      <van-list v-model="loading"
                :finished="finished"
                :finished-text="$t('没有更多了')"
                :immediate-check="false"
                @load="onLoad"
                class="img-list">
        <div v-for="(item, index) in list"
             :key="index"
             @click="Choice(item, index)"
             class="img-list-box"
             :class="current == index ? 'img-box-current' : ''">
          <img :src="item.cover"
               alt="">
          <div v-if="item.is_max == 1"
               class="img-box-top"> {{$t('最高等级')}} </div>
        </div>
      </van-list>
    </div>

    <watch-foot />

    <van-dialog v-model="sellPriceShow"
                :title="$t('请输入挂卖价格')"
                show-cancel-button
                @confirm="confirm"
                :confirm-button-text="$t('确定')"
                :cancel-button-text="$t('取消')">
      <van-field v-model="sellPrice"
                 :label="$t('价格')"
                 type="number"
                 :placeholder="$t('请输入价格')" />
    </van-dialog>

    <van-dialog v-model="regainShow"
                :title="$t('修复耐久性')"
                :before-close="regainWear"
                show-cancel-button>
      <div class="regain-num">
        <div>{{$t('请选择修复次数')}}</div>
        <div>
          <van-stepper v-model="regainNumber"
                       min="1"
                       :max="(100 - currentVal.durable) / 5"
                       integer />
        </div>
      </div>
      <div class="regain-num">
        <div>{{$t('当前修复分数')}}</div>
        <div>{{ 5 * regainNumber }}</div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import watchFoot from '@/components/watchFoot/index.vue'
import { getMyNftList, mainConfig, nftRepair, nftUpgrade, nftOrderBefore, nftHangingSale, nftCancel } from '@/api/api'
import { erc20Abi } from '@/utils/abi'
import { mapState } from 'vuex'


export default {
  components: {
    watchHead,
    watchFoot
  },
  data () {
    return {
      btnType: 'info',
      //  list: [1, 1, 1, 1, 1, 1, 1, 1]
      page: 1,
      limit: 10,
      list: [],
      loading: false,
      finished: false,
      current: 0,
      currentVal: {},
      regainLoading: false,
      regainShow: false,
      regainNumber: 1,//修复次数
      authorNftShow: true,
      sellPriceShow: false,
      sellPrice: ''
    }
  },
  computed: {
    ...mapState([
      'web3', 'nftContract', 'marketContract', 'marketContractAddress', 'solarixAccuracy'
    ]),
    percentage(){
      if(this.currentVal.grade_acount && Number(this.currentVal.grade_acount) > 0){
        if(Number(this.currentVal.acount) < Number(this.currentVal.grade_acount)){
          // console.log('进度条-----',this.currentVal.acount * 100 / this.currentVal.grade_acount);
          return this.currentVal.acount * 100 / this.currentVal.grade_acount
        }
        return 100
      }
      return 0
    },
  },
  created () {
    //  轮播图插件  https://v1.github.surmon.me/vue-awesome-swiper/
    this.waitForGlobal()
  },
  methods: {
    //取消挂卖
    cancelSell () {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '取消中...',
      });
      nftCancel({
        id: this.currentVal.id
      }).then(resuq => {
        if (resuq.code == 0) {
          this.marketContract.methods.cancel(this.currentVal.online_order_id).send({ from: localStorage.getItem('defaultAddress') }).then((res) => {
            toast.clear();
            this.$toast(this.$t('取消成功'));
            // this.currentVal.is_hanging_sale = 0 //改变挂卖状态---未挂卖
            this.currentVal.is_hanging_sale = 1
          }).catch(err => {
            toast.clear();
            this.$toast(this.$t('取消失败'));
            // this.currentVal.is_hanging_sale = 2 //改变挂卖状态---已挂卖
            this.currentVal.is_hanging_sale = 1
          })
        } else {
          toast.clear();
          this.$toast(resuq.msg);
        }
      })
    },
    //打开挂卖弹窗
    sell () {
      this.sellPriceShow = true
    },

    //挂卖
    confirm () {
      if (!this.sellPrice) {
        this.$toast(this.$t('请输入价格'));
        return;
      }
      if (this.sellPrice <= 50000) {
        this.$toast(this.$t('价格需要大于50000'));
        return;
      }
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('挂卖中...'),
      });
      nftOrderBefore({
        id: this.currentVal.id,
        price: this.sellPrice
      }).then(resuq => {
        if (resuq.code == 0) {
          nftHangingSale({
            id: this.currentVal.id,
            price: this.sellPrice
          }).then(resu => {
            if (resu.code == 0) {
              this.marketContract.methods.sell(this.currentVal.token_id, this.sellPrice * Math.pow(10, this.solarixAccuracy)).send({ from: localStorage.getItem('defaultAddress') }).then((res) => {
                toast.clear();
                this.$toast(this.$t('挂卖成功'));
                // this.currentVal.is_hanging_sale = 2 //改变挂卖状态---已挂卖
                this.currentVal.is_hanging_sale = 1
              }).catch(err => {
                toast.clear();
                this.$toast(this.$t('挂卖失败'));
                // this.currentVal.is_hanging_sale = 0 //改变挂卖状态---未挂卖
                this.currentVal.is_hanging_sale = 1
              })
            } else {
              toast.clear();
              this.$toast(resu.msg);
            }
          })
        } else {
          toast.clear();
          this.$toast(resuq.msg);
        }
      })
    },
    //nft授权
    authorNft () {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('授权中...'),
      });
      this.nftContract.methods.setApprovalForAll(
        this.marketContractAddress,
        true
      ).send({ from: localStorage.getItem('defaultAddress') }).then((res) => {
        toast.clear();
        this.$toast(this.$t('授权成功')); //授权成功
        this.authorNftShow = true
      }).catch(err => {
        toast.clear();
        this.$toast(this.$t('授权失败')); //授权失败
        this.authorNftShow = false
      })
    },
    //查询nft是否需要授权
    allowanceNft () {
      this.nftContract.methods.isApprovedForAll(localStorage.getItem('defaultAddress'), this.marketContractAddress).call({ from: localStorage.getItem('defaultAddress') }, (error, result) => {
        if (!error) {
          console.log('需要授权嘛-------', result);
          if (result) {
            //不需要授权
            this.authorNftShow = true
          } else {
            //需要授权
            this.authorNftShow = false
          }
        } else {
          console.log(error);
        }
      });
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.onLoad();
        this.allowanceNft();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    onLoad () {
      /* 获取NFT列表 */
      getMyNftList({
        page: this.page,
        limit: this.limit,
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.data)
          this.currentVal = res.data.data.length > 0 ? this.list[0] : {}
          this.loading = false;
          // if (res.data.page.total_page <= res.data.page.current_page) {
          //   this.finished = true;
          // }
          if (res.data.data.length < this.limit) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    /* 切换NFT详情 */
    Choice (val, index) {
      if (this.current !== index) {
        this.currentVal = val
        this.current = index
      }
    },

    /* NFT修复 */
    regainWear (action, done) {
      if (action === 'confirm') {
        if (this.currentVal.durable == 100) {
          this.$toast(this.$t('这张卡片不需要修复'))
          done();
          return
        }
        let toast = this.$toast.loading({
          duration: 0, // 持续展示 toast-
          forbidClick: true,
          message: this.$t('修复中...'), //修复中...
        });
        this.regainLoading = true
        mainConfig().then(res => {
          if (res.code == 0) {
            nftRepair({ id: this.currentVal.id, number: this.regainNumber }).then(resu => {
              if (resu.code == 0) {
                //     let value = new this.$Bignumber(resu.data.val * Math.pow(10, res.data.nft_repair_accuracy));
                let tokenContract = new this.web3.eth.Contract(erc20Abi, res.data.nft_repair_token);
                tokenContract.methods.transfer(res.data.nft_repair_address, this.web3.utils.fromDecimal(resu.data.money)).send({ from: localStorage.getItem('defaultAddress') })
                  .then((resuq) => {
                    console.log('转账成功了吗-----', resuq);
                    this.regainLoading = false
                    toast.clear();
                    done();
                    this.$toast(this.$t('修复成功,等待交易查询中'));
                  })
                  .catch((err) => {
                    console.log('转账失败了吗-----', err);
                    this.regainLoading = false
                    toast.clear();
                    done();
                    this.$toast(this.$t('修复失败'));
                  })
              } else {
                this.regainLoading = false
                toast.clear();
                done();
                this.$toast(resu.msg);
              }
            })
          } else {
            this.regainLoading = false
            done();
            toast.clear();
          }
        })

      } else {
        done();
      }

    },
    /* NFT升级 */
    upgrade (url) {

      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('升级中...'), //修复中...
      });
      this.regainLoading = true
      mainConfig().then(res => {
        if (res.code == 0) {
          nftUpgrade({ id: this.currentVal.id }).then(resu => {
            if (resu.code == 0) {
              //     let value = new this.$Bignumber(resu.data.val * Math.pow(10, res.data.nft_upgrade_accuracy));
              let tokenContract = new this.web3.eth.Contract(erc20Abi, res.data.nft_upgrade_token);
              tokenContract.methods.transfer(res.data.nft_upgrade_address, this.web3.utils.fromDecimal(resu.data.money)).send({ from: localStorage.getItem('defaultAddress') })
                .then((resuq) => {
                  console.log('转账成功了吗-----', resuq);
                  this.regainLoading = false
                  toast.clear();
                  //    this.$toast('升级成功');
                  this.$router.push({
                    path: url,
                    query: {
                      data: JSON.stringify(this.currentVal)
                    }
                  })
                })
                .catch((err) => {
                  console.log('转账失败了吗-----', err);
                  this.regainLoading = false
                  toast.clear();
                  this.$toast(this.$t('升级失败'));
                })
            } else {
              this.regainLoading = false
              toast.clear();
              this.$toast(resu.msg);
            }
          })
        } else {
          this.regainLoading = false
          toast.clear();
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.myNFT {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .van-dialog {
    color: #000;
  }
  .content {
    padding: 0 12px;
    margin-top: 23px;
    .roleInfo {
      font-family: '071-SSRuiFengTi';
      font-size: 26px;
      margin-bottom: 8px;
    }
    .info {
      background-color: rgba(43, 43, 43, 0.6);
      border-radius: 8px;
      padding: 17px 12px 20px 20px;
      .top {
        display: flex;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          .name {
            margin-top: 4px;
            font-family: 'AeroMaticsBoldItalic';
            font-size: 30px;
          }
          .cocobern {
            font-family: 'AeroMaticsBold';
            font-size: 18px;
            color: #f4f2f4;
            margin-bottom: 3px;
          }
          .pic {
            width: 169px;
            min-width: 169px;
            height: 169px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .higLevel {
              width: 100%;
              background-color: #a7f264;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-family: 'REEJI-PinboGB-Flash';
              font-size: 24px;
              text-align: center;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .item {
            .number {
              font-family: 'KenyanCoffeeRg-BoldItalic';
              font-size: 23px;
              text-align: center;
            }
            .text {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: '071-SSRuiFengTi';
              font-size: 14px;
              color: #a7f264;
              img {
                width: 10px;
                height: 9px;
                margin-right: 2px;
              }
            }
          }
          .item_width{
            width: 80%;
          }
          .mar_btm_8 {
            margin-bottom: 8px;
          }
        }
      }
      .roleInfo_btn {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .btn {
          margin: 0 auto;

          border-radius: 15px 3px 15px 3px;
          border: 2px solid rgba(0, 0, 0, 0.14);
          font-family: 'REEJI-PinboGB-Flash';
          font-size: 20px;
        }
        .upgrade {
          padding: 3px 60px;
          background-color: #a7f264;
          color: #2b2b2b;
        }
        .repair {
          padding: 3px 30px;
          background-color: #5c65cb;
          color: #ffffff;
        }
      }
    }
  }
  .btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .btn {
      padding: 5px 10px;
      background-color: rgba(43, 43, 43, 0.3);
      border-radius: 15px 3px 15px 3px;
      border: 2px solid transparent;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18px;
      color: #2b2b2b;
      width: 100%;
      text-align: center;
    }
    .mar_ri_10 {
      margin-right: 10px;
    }
    .btn_act {
      background-color: #a7f264;
      border: 2px solid rgba(0, 0, 0, 0.8);
    }
  }
  .screen {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 11px;
    .box {
      width: 58px;
      padding: 2px;
      background-color: rgba(53, 53, 53, 0.5);
      border-radius: 14px 0px 0px 14px;
      .circle {
        width: 24px;
        height: 24px;
        background-color: #a7f264;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 14px;
          height: 13px;
        }
      }
    }
  }

  .imgList {
    padding: 0 10px;
    display: flex;
    position: relative;
    .imgBox {
      width: 136px;
      height: 136px;
      padding: 8px;
      background-color: rgba(53, 53, 53, 0.5);
      border-radius: 10px;
      img {
        display: flex;
        width: 120px;
        height: 120px;
      }
    }
    .image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 120px;
      height: 120px;
    }

    .img-list {
      width: 100%;
      height: 30vh;
      padding: 5px;
      overflow: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      display: flex;
      flex-wrap: wrap;

      .img-list-box {
        width: 30%;
        height: 110px;
        background-color: rgba(11, 11, 11, 0.5);
        box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
        margin: 5px;
        position: relative;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
        .img-box-top {
          position: absolute;
          width: 100%;
          text-align: center;
          background-color: #a7f264;
          font-family: REEJI-PinboGB-Flash;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .img-box-current {
        box-shadow: 0px 0px 0px 6px rgba(19, 19, 19, 0.5);
        border-radius: 3px;
      }
    }
    .img-list::-webkit-scrollbar {
      display: none;
    }
  }
}
.regain-num {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  div{
     flex: 1;
     text-align: center;
  }
}
/deep/.van-list__error-text {
  width: 100% !important;
  text-align: center !important;
}
/deep/ .van-list__finished-text {
  width: 100% !important;
  text-align: center !important;
}
/deep/ .van-list__loading {
  width: 100% !important;
  text-align: center !important;
}
</style>